import { Link } from 'gatsby'
import React from 'react'

export default function CtaBtn({ children, to, className }) {
    return (
        <Link to={to} className={`px-8 py-4 inline-block text-lg bg-slate-700 shadow rounded ${className}`}>
            {children}
        </Link>
    )
}
