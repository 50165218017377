import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Projects from '../components/projects'
import CtaBtn from '../components/cta-btn'

const IndexPage = () => {
  return (
    <Layout pageTitle="Homepage">
      <div className='grid sm:grid-cols-2 gap-4'>
        <div className='mb-4'>
          <p>Welcome! My name's Chriss and I do audio and stuff.</p>
          <div class="text-center sm:text-left">
            <CtaBtn to="#projects" className="mt-8">Show Projects</CtaBtn>
          </div>
        </div>
        <StaticImage className="rounded-xl" width="600" src="https://christianhostert.com/wp-content/uploads/2022/01/PXL_20211027_141640143.MP_.jpg"></StaticImage>
      </div>
      <div id="projects" className='scroll-m-24 sm:scroll-m-16'>
        <h2 className='text-2xl mt-8'>Projects</h2>
        <Projects></Projects>
      </div>
    </Layout>
  )
}

export default IndexPage