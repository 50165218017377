import React from 'react'
import Project from './project-card'

export default function Projects() {
    return (
        <div className='grid sm:grid-cols-4 xs:grid-cols-2 gap-4 mt-4'>
            <Project name="First Project"></Project>
            <Project name="Second Project"></Project>
            <Project name="First Project"></Project>
            <Project name="Second Project"></Project>
            <Project name="First Project"></Project>
            <Project name="Second Project"></Project>
            <Project name="First Project"></Project>
            <Project name="Second Project"></Project>
            <Project name="First Project"></Project>
            <Project name="Second Project"></Project>
            <Project name="First Project"></Project>
            <Project name="Second Project"></Project>
        </div>
    )
}
