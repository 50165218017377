import React from 'react'

export default function Project({ name }) {
    return (
        <div className='aspect-square bg-gradient-to-tr from-violet-800 to-indigo-800 rounded-xl p-4 hover:scale-105 transition-all shadow-xl hover:shadow-2xl hover:shadow-black/10'        >
            <h3>
                {name}
            </h3>
        </div >
    )
}
