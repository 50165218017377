import * as React from 'react'
import { Link } from 'gatsby'

const Layout = ({ pageTitle, children }) => {
  return (
    <div>
      <nav className='sticky top-0 bg-slate-900/50 backdrop-blur z-10 border-b border-white/10'>
        <div className='max-w-7xl flex mx-auto px-10 py-4'>
          <div className='uppercase tracking-wider'>Christian Hostert</div>
          <ul className="ml-auto flex items-center">
            <li className="pl-8">
              <Link to="/">Home</Link>
            </li>
            <li className="pl-8">
              <Link to="/about">About</Link>
            </li>
          </ul>
        </div>
      </nav>
      <main className='max-w-7xl mx-auto p-10'>
        <h1 className="text-indigo-200 text-3xl mb-4">{pageTitle}</h1>
        {children}
      </main>
    </div>
  )
}

export default Layout